<template lang="pug">
div(class="page-content")
  div(class="area")
    ul(class="circles")
      li
      li
      li
      li
      li
      li
      li
      li
      li
      li
	  
  div(ref="form" class="card-login elevation-12")
    v-card-title Bem vindo
    v-card-text
      v-form(@submit.prevent="handleSubmit", ref="form")
        v-text-field(
          label="Usuário"
          name="usuario"
          v-model="usuario"
		  ßprepend-icon="fa-solid fa-user"
          required
        )

        v-text-field(
          label="Senha"
          name="senha"
          v-model="senha"
          prepend-icon="fa-solid fa-key"
          :type="mostrarSenha ? 'text' : 'password'"
          :append-icon="mostrarSenha ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="mostrarSenha = !mostrarSenha"
          app
          required
        )
        v-layout(justify-center)
          v-btn(
			type="submit",
			color="primary"
			:loading="enviado"
			) Entrar
</template>

<script>
export default {
  data: () => ({
    usuario: '',
    senha: '',
    enviado: false,
    mostrarSenha: false,
  }),
  methods: {
    async handleSubmit() {
      const { usuario, senha } = this;
      if (usuario && senha) {
        this.enviado = true;
        try {
					await this.$store.dispatch('auth/login', { usuario, senha });
          await this.$store.dispatch('auth/csApiLogin');
        } finally {
          this.enviado = false;
        }
      }
    },
  },
  computed: {
  },
  created() {
    this.$store.dispatch('auth/checkLogin');
  },
};
</script>

<style>
  .theme--light.v-application {
    background-color: transparent;
  }

  .page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .card-login {
    width: 400px;
    max-width: 100%;
    margin: 0 auto;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

	@-webkit-keyframes animatetop {
		from {
			top: -300px;
			opacity: 0;
		}
		to {
			top: 0;
			opacity: 1;
		}
	}
	@keyframes animatetop {
		from {
			top: -300px;
			opacity: 0;
		}
		to {
			top: 0;
			opacity: 1;
		}
	}
	@-webkit-keyframes zoomIn {
		0% {
			opacity: 0;
			-webkit-transform: scale3d(0.3, 0.3, 0.3);
			transform: scale3d(0.3, 0.3, 0.3);
		}
		50% {
			opacity: 1;
		}
	}
	@keyframes zoomIn {
		0% {
			opacity: 0;
			-webkit-transform: scale3d(0.3, 0.3, 0.3);
			transform: scale3d(0.3, 0.3, 0.3);
		}
		50% {
			opacity: 1;
		}
	}
	/*End Animations*/
	/*
-- Start BackGround Animation
*/
	.area {
    /* green */
		background: #008000;
		background: -webkit-linear-gradient(to left, #84d6a6, #28c465);
		width: 100%;
		height: 100vh;
		position: absolute;
		z-index: -1;
	}

	.circles {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 96%;
		overflow: hidden;
	}

	.circles li {
		position: absolute;
		display: block;
		list-style: none;
		width: 20px;
		height: 20px;
		background: rgba(255, 255, 255, 0.2);
		animation: animate 25s linear infinite;
		bottom: -150px;
	}

	.circles li:nth-child(1) {
		left: 25%;
		width: 80px;
		height: 80px;
		animation-delay: 0s;
	}

	.circles li:nth-child(2) {
		left: 10%;
		width: 20px;
		height: 20px;
		animation-delay: 2s;
		animation-duration: 12s;
	}

	.circles li:nth-child(3) {
		left: 70%;
		width: 20px;
		height: 20px;
		animation-delay: 4s;
	}

	.circles li:nth-child(4) {
		left: 40%;
		width: 60px;
		height: 60px;
		animation-delay: 0s;
		animation-duration: 18s;
	}

	.circles li:nth-child(5) {
		left: 65%;
		width: 20px;
		height: 20px;
		animation-delay: 0s;
	}

	.circles li:nth-child(6) {
		left: 75%;
		width: 110px;
		height: 110px;
		animation-delay: 3s;
	}

	.circles li:nth-child(7) {
		left: 35%;
		width: 150px;
		height: 150px;
		animation-delay: 7s;
	}

	.circles li:nth-child(8) {
		left: 50%;
		width: 25px;
		height: 25px;
		animation-delay: 15s;
		animation-duration: 45s;
	}

	.circles li:nth-child(9) {
		left: 20%;
		width: 15px;
		height: 15px;
		animation-delay: 2s;
		animation-duration: 35s;
	}

	.circles li:nth-child(10) {
		left: 85%;
		width: 150px;
		height: 150px;
		animation-delay: 0s;
		animation-duration: 11s;
	}

	@keyframes animate {
		0% {
			transform: translateY(0) rotate(0deg);
			opacity: 1;
			border-radius: 0;
		}

		100% {
			transform: translateY(-1000px) rotate(720deg);
			opacity: 0;
			border-radius: 50%;
		}
	}
</style>
